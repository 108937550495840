import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import SanityImage from "gatsby-plugin-sanity-image";

const StyledContainer = styled.div`
    ${tw`flex flex-col items-center md:mx-10 mx-4 md:(p-12 px-10) px-6 py-8 bg-white`};
    > div {
        ${tw`mx-auto`}
    }
`;

const ImageWrapper = styled.div`
    justify-content: center;
    display: flex;
`;
const StyledSanityImage = styled(SanityImage)`
    width: 100%;
    max-width: 9rem;
    filter: grayscale(100%);
    opacity: .5;
    :hover {
        filter: grayscale(0%);
    }

    @media only screen and (max-width: 1048px) {
        max-width: 18vw;
    }
`;

const LogoContainer = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    div {
        @media only screen and (max-width: 1048px) {
            flex: 0 0 calc(33%);
        }
        ${tw` mx-0`}
    }
`;

const LogoBanner = ({ block }) => {
    return (
        <StyledContainer>
            <LogoContainer>
                {block.logos.map((logo, index) => {
                    return (
                        <ImageWrapper key={index}>
                            <StyledSanityImage
                                key={index}
                                {...logo.image}
                                width={300}
                                alt={logo.title}
                            />
                        </ImageWrapper>
                    );
                })}
            </LogoContainer>
        </StyledContainer>
    );
};

export default LogoBanner;

export const query = graphql`
    fragment LogoBannerFragment on SanityLogoBanner {
        _key
        _type
        logos {
            image {
                ...ImageWithPreview
            }
            title
        }
    }
`;
