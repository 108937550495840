import React, { useState } from "react";
import { graphql, Link,  navigate} from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import SanityImage from "gatsby-plugin-sanity-image";
import PortableText from "@sanity/block-content-to-react";
import { MdChevronRight } from "react-icons/md";
import { IoMdCheckmark } from "react-icons/io";

// maintain: Here you have to add a new country
const thankYouTexts = {
    us: {
        h1: "Thank you!",
        p: "We will contact you soon."
    },
    da: {
        h1: "Tak!",
        p: "Vi kontakter dig snart."
    },
    no: {
        h1: "Takk!",
        p: "Vi kommer snart til å kontakte deg."
    },
}

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const Container = styled.div`
    ${tw`md:(mx-10 items-center) flex flex-col items-start mx-4 md:p-28 py-6 px-6 bg-white`};
    > div {
        ${tw`md:(mx-auto w-1/2) w-full`}
    }
    p:last-child {
      margin-bottom: 2rem;
    }
`;

const StyledLink = styled(Link)`
    color: #808dff;
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 1rem;
    margin: 0.5rem;
`;

const CheckBox = styled.div`
  ${tw`flex justify-center items-center mr-4`}
  min-width: 40px;
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 16px;
  cursor: pointer;
  border: 2px solid ${props => props.invalid ? "#E16056" : "#f0e8e0"};
`;

const StyledPortableText = styled(PortableText)`
    ${tw`flex-1 flex-col md:w-1/2`}
`;

const StyledPortableTextTerms = styled(PortableText)`
    ${tw`flex-1 flex-col w-1/2 mb-8`}
`;

const Form = styled.div`
    ${tw`flex items-center flex-col md:(flex-col w-1/2) `}
`;

const FormField = styled.div`
    ${tw`mb-6 flex flex-col md:flex-row justify-between  w-full`}
`;

const StyledInput = styled.input`
    ${tw`text-quickDark placeholder-quickDark placeholder-opacity-40`}
    border: 2px solid ${props => props.invalid ? "#E16056" : "#f0e8e0"};
    transition: all 0.2s;
    :focus {
        ${tw`border-quickSecondary`}
    }
`;
const StyledButton = styled.button`
    ${tw`bg-quickPrimary text-quickSandLighter`}
    border: none;
    font-weight: 700;
    transition: all 0.2s;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.06);
    :hover {
        box-shadow: 0 16px 20px rgba(0, 0, 0, 0.1);
    }
    :active {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);;
    }
    cursor: pointer;
`;

const ImageWrapper = styled.div`
    ${tw`w-full flex`}
    min-height: 200px;
`;

const Left = styled.div`
    ${tw`mr-8`}
`;

const Right = styled.div``;

const TopRight = styled.div`
    ${tw`mt-8`}
`;

const BottomRight = styled.div`
    ${tw`m-8`}
`;

const LeadForm = ({ block }) => {
    const url = typeof window !== 'undefined' ? window.location.href : '';

    const [checked, setChecked] = useState(false);
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [buttonClicked, setButtonClicked] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isFormSent, setIsFormSent] = useState(false);

    const formIsValid = checked && name && company && phone  && validateEmail(email)

    const data = {
        name: name,
        company: company,
        phone: phone,
        email: email,
        block: block,
        page_url: url,
        consent: checked,
    }
    const serializers = {
        types: {
            internalLink: ({ node }) => {
                return (
                    <div>
                        <StyledLink to={"/" + node.page?.slug.current}>
                            <p>{node.linkText}</p>
                            <MdChevronRight size={36} color="#808DFF" />
                        </StyledLink>
                    </div>
                );
            },
        },
    };
    return (
        <Container>
            {(block.imageLeft ||
                block.imageTopRight ||
                block.imageBottomRight) && (
                <ImageWrapper>
                    {block.imageLeft && (
                        <Left>
                            <SanityImage
                                {...block.imageLeft}
                                width={300}
                                alt={block.imageLeft.title}
                            />
                        </Left>
                    )}
                    <Right>
                        {block.imageTopRight && (
                            <TopRight>
                                <SanityImage
                                    {...block.imageTopRight}
                                    width={300}
                                    alt={block.imageTopRight.title}
                                />
                            </TopRight>
                        )}
                        {block.imageBottomRight && (
                            <BottomRight>
                                <SanityImage
                                    {...block.imageBottomRight}
                                    width={300}
                                    alt={block.imageBottomRight.title}
                                />
                            </BottomRight>
                        )}
                    </Right>
                </ImageWrapper>
            )}
            {isFormSent ? (
                <>
                    <h1>{thankYouTexts[block.country].h1}</h1>
                    <p>{thankYouTexts[block.country].p}</p>
                </>
            ) : (
                <>
                <StyledPortableText
                    blocks={block._rawText}
                    serializers={serializers}
                />
                    <Form>
                        <FormField>
                            <StyledInput
                                type="text"
                                name="name"
                                placeholder={block.placeholderName}
                                value={name}
                                onChange={(event) => setName(event.target.value)}
                                required
                                invalid={buttonClicked && !name}
                                tw="md:w-full"
                            />
                        </FormField>
                        <FormField>
                            <StyledInput
                                type="text"
                                name="company"
                                placeholder={block.placeholderCompany}
                                value={company} 
                                onChange={(event) => setCompany(event.target.value)}
                                required
                                invalid={buttonClicked && !company}
                                tw="md:w-full"
                            />
                        </FormField>
                        <FormField>
                            <StyledInput
                                type="text"
                                name="phone"
                                placeholder={block.placeholderPhone}
                                value={phone} 
                                onChange={(event) => setPhone(event.target.value)}
                                required
                                invalid={buttonClicked && !phone}
                                tw="md:w-full"
                            />
                        </FormField>

                        <FormField>
                            <StyledInput
                                type="text"
                                name="email"
                                placeholder={block.placeholderEmail}
                                value={email} 
                                onChange={(event) => setEmail(event.target.value)}
                                required
                                invalid={buttonClicked && !validateEmail(email)}
                                tw="md:w-full"
                            />
                        </FormField>

                        <div tw="flex w-full items-start">
                            <CheckBox checked={checked}                         
                            invalid={buttonClicked && !checked}
                            onClick={() => setChecked(!checked)}
                            >
                                {checked ? <IoMdCheckmark size={32} /> : null}
                            </CheckBox>
                            <StyledPortableTextTerms
                                blocks={block._rawApproveTermsText}
                            />
                        </div>
                        <StyledButton
                            tw="w-full"
                            onClick={() => {
                                // add classes if invalid
                                setButtonClicked(true)
                                setIsButtonDisabled(true)
                                setTimeout(()=> {
                                    setIsButtonDisabled(false)
                                }, 1000)
                                if (formIsValid && !isButtonDisabled) {

                                    fetch(block.zapierLink, {
                                        method: "POST", // or 'PUT'
                                        body: JSON.stringify(data),
                                    })
                                    .then(response => {
                                        if(
                                            response.ok &&
                                            data.consent &&
            
                                            data.name.length &&
                                            data.company.length &&
                                            data.phone.length &&
                                            data.email.length
                                        ) {
            
                                            // const segmentData = {
                                            //     country: block.country,
                                            //     email: data.email,
                                            //     name: data.name,
                                            //     phone: data.phone,
                                            //     lifecyclestage: "lead",
                                            //     hs_analytics_source: "Direct Traffic",
                                            //     api_source: "Segment",
                                            //     companyName: data.company,
                                            // };
                                            //
                                            // if(window.analytics) {
                                            //     window.analytics.track("Lead Form Submitted", segmentData);
                                            //     window.analytics.identify(segmentData);
                                            // }

                                            setIsFormSent(true)
                                        }
                                    });
                                }

                            }}
                        >
                            {block.buttonText}
                        </StyledButton>
                    </Form>
                </>
            )}
        </Container>
    );
};

export default LeadForm;

export const query = graphql`
    fragment LeadFormFragment on SanityLeadForm {
        _key
        _type
        _rawText(resolveReferences: { maxDepth: 10 })
        _rawApproveTermsText(resolveReferences: { maxDepth: 10 })
        country
        placeholderName
        placeholderCompany
        placeholderPhone
        placeholderEmail
        zapierLink
        buttonText
        buttonColor {
            hexcode
        }
        backgroundColor {
            hexcode
            colorName
        }
        imageLeft {
            ...ImageWithPreview
        }
        imageTopRight {
            ...ImageWithPreview
        }
        imageBottomRight {
            ...ImageWithPreview
        }
    }
`;
