import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import SanityImage from "gatsby-plugin-sanity-image";
import PortableText from "@sanity/block-content-to-react";
import { MdChevronRight } from "react-icons/md";
import Container from "../../ui/Container";

const StyledLink = styled(Link)`
    color: #808dff;
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 1rem;
    margin: 0.5rem;
`;

const StyledPortableText = styled(PortableText)`
    ${tw`flex-1 flex-col mx-auto md:w-2/3 w-full`}
    p {
        margin-bottom: 4rem;
    }
`;

const Form = styled.div`
    ${tw`flex items-center flex-col md:(flex-row w-2/3)  mx-auto`}
`;


const StyledInput = styled.input`
    ${tw`flex flex-1 items-center h-4 md:(mr-8 mb-0) mb-10`}
    color: #808dff;
    border: 2px solid #f0e8e0;
    border-radius: 16px;
    padding: 18px 24px;
    color: #1e1e3280;
    font-family: inherit;
`;

const StyledButton = styled.button`
    ${tw`bg-quickPrimary text-white`}
    border: 0px;
    border-radius: 16px;
    padding: 18px 24px;
    font-size: 1rem;
    font-weight: 700;
    @media only screen and (max-width: 768px) {
        font-size: 0.85rem;
    }
`;

const ImageWrapper = styled.div`
    ${tw`w-full flex`}
    min-height: 200px;
`;

const Left = styled.div`
    ${tw`mr-8`}
`;

const Right = styled.div``;

const TopRight = styled.div`
    ${tw`mt-8`}
`;

const BottomRight = styled.div`
    ${tw`m-8`}
`;

const Signup = ({ block }) => {
    // todo refactor PortableText into 1 component with all serializers
    const serializers = {
        types: {
            internalLink: ({ node }) => {
                return (
                    <div>
                        <StyledLink to={"/" + node.page?.slug.current}>
                            <p>{node.linkText}</p>
                            <MdChevronRight size={36} color="#808DFF" />
                        </StyledLink>
                    </div>
                );
            },
        },
    };
    
    return (
        <Container>
            {(block.imageLeft ||
                block.imageTopRight ||
                block.imageBottomRight) && (
                <ImageWrapper>
                    {block.imageLeft && (
                        <Left>
                            <SanityImage
                                {...block.imageLeft}
                                width={300}
                                alt={block.imageLeft.title}
                            />
                        </Left>
                    )}
                    <Right>
                        {block.imageTopRight && (
                            <TopRight>
                                <SanityImage
                                    {...block.imageTopRight}
                                    width={300}
                                    alt={block.imageTopRight.title}
                                />
                            </TopRight>
                        )}
                        {block.imageBottomRight && (
                            <BottomRight>
                                <SanityImage
                                    {...block.imageBottomRight}
                                    width={300}
                                    alt={block.imageBottomRight.title}
                                />
                            </BottomRight>
                        )}
                    </Right>
                </ImageWrapper>
            )}

            <StyledPortableText
                blocks={block._rawText}
                serializers={serializers}
            />
            <Form>
                <StyledInput type="text" placeholder={block.placeholder} />
                <StyledButton
                    onClick={() => {
                        // alert(10);
                    }}
                >
                    {block.buttonText}
                </StyledButton>
            </Form>
        </Container>
    );
};

export default Signup;

export const query = graphql`
    fragment SignupFragment on SanitySignup {
        _key
        _type
        _rawText(resolveReferences: { maxDepth: 10 })
        placeholder
        buttonText
        buttonColor {
            hexcode
        }
        backgroundColor {
            hexcode
            colorName
        }
        imageLeft {
            ...ImageWithPreview
        }
        imageTopRight {
            ...ImageWithPreview
        }
        imageBottomRight {
            ...ImageWithPreview
        }
    }
`;
