import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import SanityImage from "gatsby-plugin-sanity-image";
import PortableText from "@sanity/block-content-to-react";

const Container = styled.div`
    ${tw`flex items-center flex-col md:(flex-row relative py-36 mx-10) mx-4 bg-white`}
    padding-left: 6.8rem;
    padding-right: 6.8rem;
    @media only screen and (max-width: 768px) {
        padding-left: 0;
        padding-right: 0;
    }
    > div {
        ${tw`mx-auto`}
    }
`;

const StyledPortableText = styled(PortableText)`
    flex: 1;
    ${tw`flex flex-col lg:w-1/2 px-6 md:(px-0)`}
`;

const Wrapper = styled.div`
    ${tw`flex lg:(flex-row) flex-col flex-1`}
`;
const First = styled.div`
    ${tw`lg:(w-1/2 pr-8 py-28) py-8 flex flex-1  `}
`;

const Second = styled.div`
    ${tw`lg:(w-1/2) flex flex-1 `}
`;
const InnerSecond = styled.div`
    ${tw`w-1/2 flex flex-1 flex-col lg:(relative flex-row)`}
    .left {
        ${tw`lg:(absolute bottom-0) mx-auto mt-4`}
        width: 45%;
        max-width: 20vw;
        @media only screen and (max-width: 768px) {
            ${tw`mb-4 md:(mb-0)`}
            width: 85%;
            max-width: 100vw;
        }
    }
    .right {
        ${tw`lg:(absolute right-0 top-0)`}
        width: 65%;
        max-width: 25vw;
        @media only screen and (max-width: 768px) {
            width: 100%;
            max-width: 100vw;
        }
    }
`;

const StyledSanityImage = styled(SanityImage)`
    width: 50px;
`;

const TextWithTwoImages = ({ block }) => {
    return (
        <Container>
            <Wrapper>
                <First>
                    <StyledPortableText blocks={block._rawText} />
                </First>
                <Second>
                    <InnerSecond>
                        <StyledSanityImage
                            className="right"
                            {...block.imageRight.image}
                            alt={block.imageRight.title}
                        />
                        <StyledSanityImage
                            className="left"
                            {...block.imageLeft.image}
                            alt={block.imageLeft.title}
                        />
                    </InnerSecond>
                </Second>
            </Wrapper>
        </Container>
    );
};

export default TextWithTwoImages;

export const query = graphql`
    fragment TextWithTwoImagesFragment on SanityTextWithTwoImages {
        _key
        _type
        _rawText(resolveReferences: { maxDepth: 10 })
        imageLeft {
            _key
            _type
            image {
                ...ImageWithPreview
            }
            title
        }
        imageRight {
            _key
            _type
            image {
                ...ImageWithPreview
            }
            title
        }
    }
`;
