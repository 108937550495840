import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import SanityImage from "gatsby-plugin-sanity-image";
import PortableText from "@sanity/block-content-to-react";
import { MdChevronRight } from "react-icons/md";
import ImageSwitcher from "../../ui/ImageSwitcher";

const Container = styled.div`
    ${tw`flex flex-col items-center md:mx-10 mx-4 md:py-28 py-8 px-6 bg-white bg-quickDark text-quickSandLighter`};
    p {
        max-width: 60rem;
        font-size: 2rem;
        line-height: 2.66rem;
        letter-spacing: -0.02rem;
        font-weight: 400;
        @media only screen and (max-width: 768px) {
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }

`;

const StyledLink = styled(Link)`
    color: #808dff;
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 1rem;
`;

const StyledPortableText = styled(PortableText)`
    h1 {
        margin-top: 0;
        font-size: 3rem;
        line-height: 4rem;
        letter-spacing: -0.02rem;
        font-weight: 700;
        /* Below is h4 styling from global.color.ts*/
        @media only screen and (max-width: 768px) {
            font-size: 2rem;
            line-height: 2.66rem;
            letter-spacing: -0.02rem;
            font-weight: 700;
        }
    }
    text-align: left;
    ${tw`flex flex-col`}
`;

const TextBanner = ({ block }) => {
    const [activeSlide, setActiveSlide] = useState(0);
    
    const serializers = {
        types: {
            internalLink: ({ node }) => {
                return (
                    <div>
                        <StyledLink to={"/" + node.page?.slug.current}>
                            <span>{node.linkText}</span>
                            <MdChevronRight size={36} color="#808DFF" />
                        </StyledLink>
                    </div>
                );
            },
            imageSwitch: ({ node }) => {
                return <ImageSwitcher block={block} activeSlide={activeSlide} setActiveSlide={(i) => {setActiveSlide(i)}}/>;
            },
        },
    };

    return (
        <Container textLeft={block.textLeft}>
            <StyledPortableText
                blocks={block._rawText}
                serializers={serializers}
            />
         
        </Container>
    );
};

export default TextBanner;

export const query = graphql`
    fragment TextBannerFragment on SanityTextBanner {
        _key
        _type
        _rawText(resolveReferences: { maxDepth: 10 })
    }
`;
