import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import { MdChevronRight } from "react-icons/md";


const StyledLink = styled(Link)`
    ${tw`flex items-center h-4 m-2 no-underline text-quickSecondary`}
    svg {
      transition: transform 0.3s;
      transform: translateX(0);
    }
    &:hover {
        svg {
          transform: translateX(0.5rem);
        }
    }
    &:active {
        opacity: 0.85;
    }
    p {
        margin: 0;
    }
`;

export default function LinkWithArrow({to, text}) {
    return (
        <StyledLink to={to}>
            <div tw="flex items-center">
                <p>{text}</p>
                <MdChevronRight tw="m-0" size={36} />
            </div>
        </StyledLink>
    );
}
