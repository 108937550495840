import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import SanityImage from "gatsby-plugin-sanity-image";

const Container = styled.div`
    max-height: 50vh;
    overflow: hidden;
    ${tw`flex flex-col items-center md:mx-10 mx-4 md:p-28 md:py-0 md:px-0 bg-white`};
`;

const ImageWrapper = styled.div`
    flex: 1;
`;
const StyledSanityImage = styled(SanityImage)`
    margin-left: auto;
    margin-right: auto;
`;

const ImageBanner = ({ block }) => {
    return (
        <Container>
            <ImageWrapper>
                <StyledSanityImage
                    {...block.image}
                    alt={block.image.title}
                />
            </ImageWrapper>
        </Container>
    );
};

export default ImageBanner;

export const query = graphql`
    fragment ImageBannerFragment on SanityImageBanner {
        _key
        _type
        title
        image {
            ...ImageWithPreview
        }
    }
`;
