import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import PortableText from "@sanity/block-content-to-react";
import CaseCard from "./CaseCard";
import { MdChevronRight } from "react-icons/md";

const Container = styled.div`
    ${tw`flex flex-col md:(mx-10 p-28) mx-4 py-8 px-6 bg-white overflow-hidden`};
    > div {
        max-width: 1280px;
        // ${tw`mx-auto`}
    }
`;

const StyledLink = styled(Link)`
    color: #808dff;
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 1rem;
    margin-top: 2rem;
`;

const StyledPortableText = styled(PortableText)`
    text-align: left;
    margin-right: auto;
    max-width: 30rem;
    ${tw`flex-1 flex-col mx-0`}
    h1 {
        margin-top: 0;
        font-size: 3rem;
        line-height: 4rem;
        letter-spacing: -0.02rem;
        font-weight: 700;
        /* Below is h4 styling from global.color.ts*/
        @media only screen and (max-width: 768px) {
            font-size: 2rem;
            line-height: 2.66rem;
            letter-spacing: -0.02rem;
            font-weight: 700;
        }
    }
    p {
        margin-right: auto;
        max-width: 30rem;
    }
`;

const Grid = styled.div`
    ${tw`flex md:flex-wrap md:justify-between`}
    width: 100%;
    overflow: auto;
    white-space: nowrap;
    font-size: 0;
`;

const CardGrid = ({ block }) => {
    const { _rawText, cards, link } = block;
    return (
        <Container>
            <div>
                <div tw="flex items-start mx-0">
                    <StyledPortableText blocks={_rawText} />
                </div>
                <Grid>
                    {cards.map((card, key) => {
                        return <CaseCard card={card} />;
                    })}
                </Grid>
                {link ? (
                    <StyledLink to={link.page?.slug.current}>
                        <span>{link.linkText}</span>
                        <MdChevronRight size={36} color="#808DFF" />
                    </StyledLink>
                ) : null}
            </div>
        </Container>
    );
};

export default CardGrid;

export const query = graphql`
    fragment CardGridFragment on SanityCardGrid {
        _key
        _type
        _rawText(resolveReferences: { maxDepth: 10 })
        cards {
            _key
            _type
            tag
            image {
                ...ImageWithPreview
            }
            title
            venues
            employees
            devices
            link {
                slug {
                    current
                }
            }
        }
        link {
            linkText
            page {
                slug {
                    current
                }
            }
        }
    }
`;
