import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import PortableText from "@sanity/block-content-to-react";
import { MdChevronRight } from "react-icons/md";

const StyledLink = styled(Link)`
  color: #808dff;
  text-decoration: none;
  display: flex;
  align-items: center;
  height: 1rem;
  margin: 0.5rem;
`;

const StyledContainer = styled.div`
  ${tw`flex flex-col items-center md:(mx-10 p-28) mx-4 py-6 px-6 bg-white`};
`;

const FormWrapper = styled.div`
  ${tw`md:w-2/3 w-full`}
`;

const StyledPortableText = styled(PortableText)`
  ${tw`flex-1 flex-col mx-auto md:w-2/3 w-full`}
  p {
    margin-bottom: 4rem;
  }
`;

const HubspotForm = ({ block }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (typeof window !== "undefined" && window.hbspt) {
        window.hbspt.forms.create({
          region: block.region || "na1",
          portalId: block.portalId || "6269547",
          formId: block.formId,
          target: `#hubspotForm-${block.formId}`,
        });
      }
    });
  }, []);

  const serializers = {
    types: {
      internalLink: ({ node }) => {
        return (
          <div>
            <StyledLink to={"/" + node.page?.slug.current}>
              <p>{node.linkText}</p>
              <MdChevronRight size={36} color="#808DFF" />
            </StyledLink>
          </div>
        );
      },
    },
  };

  return (
    <StyledContainer id="lead-form">
      <StyledPortableText blocks={block._rawText} serializers={serializers} />
      <FormWrapper id={`hubspotForm-${block.formId}`}></FormWrapper>
    </StyledContainer>
  );
};

export default HubspotForm;

export const query = graphql`
  fragment HubspotFormFragment on SanityHubspotForm {
    _key
    _type
    _rawText(resolveReferences: { maxDepth: 10 })
    region
    portalId
    formId
  }
`;
