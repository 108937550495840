import React from "react";
/* Blocks */
import ExampleBlock from "../components/content/ExampleBlock";
import CTA from "../components/content/CTA";
import LogoWithText from "../components/content/LogoWithText";
import Pricing from "../components/content/Pricing";
import {FAQ} from "../components/content/FAQ";
import RichText from "../components/content/RichText";
import Signup from "./content/Signup";
import LeadForm from "./content/LeadForm";
import Hero from "../components/content/Hero";
import HeroCentered from "../components/content/HeroCentered";
import HorizontalRule from "../components/content/HorizontalRule";
import HubspotForm from "../components/content/HubspotForm";
import ArticleGrid from "../components/content/ArticleGrid";
import CookieDeclaration from "../components/content/CookieDeclaration";
import CardGrid from "../components/content/CardGrid";
import FeatureGrid from "../components/content/FeatureGrid";
import ImageBanner from "../components/content/ImageBanner";
import LogoBanner from "../components/content/LogoBanner";
import TextWithTwoImages from "../components/content/TextWithTwoImages";
import CTAOverImage from "../components/content/CTAOverImage";
import TextBanner from "./content/TextBanner";
import TextBannerWithBackground from "./content/TextBannerWithBackground";
import VideoBanner from "./content/VideoBanner";
import Testimonials from "./content/Testimonials";
import ClientCases from "./content/ClientCases";
// maintain: Here you have to add a new UI component

function PageBuilder({ blocks, ...props }) {
  const makeKey = (block, index) => `${block._type}_${index}`;

  return blocks.map((block, i) => {
    if (block) {
      switch (block._type) {
        case "exampleblock":
          return <ExampleBlock key={makeKey(block, i)} block={block} {...props} />;
        case "ctaOverImage":
          return <CTAOverImage key={makeKey(block, i)} block={block} {...props} />;
        case "cta":
          return <CTA key={makeKey(block, i)} block={block} {...props} />;
          case "logoWithText":
          return <LogoWithText key={makeKey(block, i)} block={block} {...props} />;
        case "pricing":
          return <Pricing key={makeKey(block, i)} block={block} {...props} />;
        case "faq":
          return <FAQ key={makeKey(block, i)} block={block} {...props} />;
        case "richText":
          return <RichText key={makeKey(block, i)} block={block} {...props} />;
        case "textWithTwoImages":
          return <TextWithTwoImages key={makeKey(block, i)} block={block} {...props} />;
        case "signup":
          return <Signup key={makeKey(block, i)} block={block} {...props} />;
        case "leadForm":
          return <LeadForm key={makeKey(block, i)} block={block} {...props} />;
        case "imageBanner":
          return <ImageBanner key={makeKey(block, i)} block={block} {...props} />;
        case "logoBanner":
          return <LogoBanner key={makeKey(block, i)} block={block} {...props} />;
        case "textBanner":
          return <TextBanner key={makeKey(block, i)} block={block} {...props} />;        
        case "textBannerWithBackground":
          return <TextBannerWithBackground key={makeKey(block, i)} block={block} {...props} />;
        case "videoBanner":
          return <VideoBanner key={makeKey(block, i)} block={block} {...props} />;
        case "hero":
          return <Hero key={makeKey(block, i)} block={block} {...props} />;
        case "heroCentered":
          return <HeroCentered key={makeKey(block, i)} block={block} {...props} />;
        case "horizontalRule":
          return <HorizontalRule key={makeKey(block, i)} block={block} {...props} />;
        case "hubspotForm":
          return <HubspotForm key={makeKey(block, i)} block={block} {...props} />;
        case "articleGrid":
          return <ArticleGrid key={makeKey(block, i)} block={block} {...props} />;
        case "cookieDeclaration":
          return <CookieDeclaration key={makeKey(block, i)} block={block} {...props} />;
        case "cardGrid":
          return <CardGrid key={makeKey(block, i)} block={block} {...props} />;
        case "featureGrid":
          return <FeatureGrid key={makeKey(block, i)} block={block} {...props} />;
        case "testimonials":
          return <Testimonials key={makeKey(block, i)} block={block} {...props} />;
        case "clientCases":
          return <ClientCases key={makeKey(block, i)} block={block} {...props} />;
        // maintain: Here you have to add a new UI component
        default:
          return console.log(`No block of type ${block._type} found.`);
      }
    }
  });
}

export default PageBuilder;

PageBuilder.defaultProps = {};
PageBuilder.propTypes = {};
