import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import SanityImage from "gatsby-plugin-sanity-image";
import PortableText from "@sanity/block-content-to-react";
import { MdChevronRight } from "react-icons/md";
import { useViewport } from "../../../hooks/useViewport";
import { isViewport } from "../../../helpers";
import ImageSwitcher from "../../ui/ImageSwitcher";

const StyledLink = styled(Link)`
    color: #808dff;
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 1rem;
    margin-bottom: 2rem;
`;

const StyledPortableText = styled(PortableText)`
    text-align: left;
    ${tw`flex flex-col`}
    h1 {
        margin-top: 0;
        font-size: 3rem;
        line-height: 4rem;
        letter-spacing: -0.02rem;
        font-weight: 700;
        /* Below is h4 styling from global.color.ts*/
        @media only screen and (max-width: 768px) {
            font-size: 2rem;
            line-height: 2.66rem;
            letter-spacing: -0.02rem;
            font-weight: 700;
        }
    }
    p {
        max-width: 48rem;
    }
`;

const ImageWrapper = styled.div`
    flex: 1;
`;

const StyledSanityImage = styled(SanityImage)`
    width: 40%;
    margin-left: auto;
    margin-right: auto;
`;

const Container = styled.div`
    ${tw`flex items-center md:(mx-10) mx-4 md:(py-28) py-8  bg-white text-quickSand bg-quickDark`};

    > div {
        ${tw`flex items-center mx-auto`};
        flex-direction: ${({phone, textLeft}) =>
            textLeft && !phone
                ? "row"
                : phone
                ? "column"
                : "row-reverse"};
        padding-left: ${({phone, textLeft}) =>
            textLeft && !phone ? "6rem" : phone ? "1rem" : "0rem"};
        padding-right: ${({phone, textLeft}) =>
            !textLeft && !phone ? "6rem" : phone ? "1rem" : "0rem"};
        max-width: 1280px;
    }
`;

const LogoWithText = ({ block }) => {
    const [activeSlide, setActiveSlide] = useState(0);
    const viewport = useViewport();
    const phone = isViewport(viewport, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"]);
    const serializers = {
        types: {
            internalLink: ({ node }) => {
                return (
                    <div>
                        <StyledLink to={"/" + node.page?.slug.current}>
                            <span>{node.linkText}</span>
                            <MdChevronRight size={36} color="#808DFF" />
                        </StyledLink>
                    </div>
                );
            },
            imageSwitch: ({ node }) => {
                return <ImageSwitcher block={block} activeSlide={activeSlide} setActiveSlide={(i) => {setActiveSlide(i)}}/>;
            },
        },
    };

    return (
        <Container textLeft={block.textLeft} phone={phone}>
            <div>
                <StyledPortableText
                    blocks={block._rawText}
                    serializers={serializers}
                />
                {block.images.map((image, index) => {
                    return (
                        <>
                            {image && index === activeSlide ? (
                                <ImageWrapper>
                                    <StyledSanityImage
                                        {...image.image}
                                        alt={image.title}
                                    />
                                </ImageWrapper>
                            ) : null}
                        </>
                    );
                })}
            </div>
        </Container>
    );
};

export default LogoWithText;

export const query = graphql`
    fragment LogoWithTextFragment on SanityLogoWithText {
        _key
        _type
        textLeft
        _rawText(resolveReferences: { maxDepth: 10 })
        images {
            ... on SanityImageWithTitle {
                _key
                _type
                image {
                    ...ImageWithPreview
                }
                title
            }
        }
    }
`;
