import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import PortableText from "@sanity/block-content-to-react";
import FeatureCard from "./FeatureCard";
const Container = styled.div`
    ${tw`flex flex-col md:mx-10 mx-4 md:p-28 py-16 px-6 bg-white`};
    > div {
        max-width: 1280px;
    }
`;

const StyledPortableText = styled(PortableText)`
    text-align: left;
    margin-right: auto;
    ${tw`flex-1 flex-col mx-0`}
    h1 {
        margin-top: 0;
        font-size: 3rem;
        line-height: 4rem;
        letter-spacing: -0.02rem;
        font-weight: 700;
        /* Below is h4 styling from global.color.ts*/
        @media only screen and (max-width: 768px) {
            font-size: 2rem;
            line-height: 2.66rem;
            letter-spacing: -0.02rem;
            font-weight: 700;
        }
    }
    p {
        margin-right: auto;
        margin-bottom: 4rem;
        max-width: 48rem;
    }
`;

const Grid = styled.div`
    ${tw`flex md:flex-wrap md:overflow-x-hidden overflow-x-scroll md:justify-evenly`}
`;

const FeatureGrid = ({ block }) => {
    const { _rawText, cards } = block;
    return (
        <Container>
            <div>
                <div tw="flex items-start mx-0">
                    <StyledPortableText blocks={_rawText} />
                </div>
                <Grid>
                    {cards.map((feature, key) => {
                        return <FeatureCard feature={feature} index={key} />;
                    })}
                </Grid>
            </div>
        </Container>
    );
};

export default FeatureGrid;

export const query = graphql`
    fragment FeatureGridFragment on SanityFeatureGrid {
        _key
        _type
        _rawText(resolveReferences: { maxDepth: 10 })
        cards {
            _key
            _type
            title
            subtitle
            link {
                slug {
                    current
                }
            }
            externalLink
            framedImage {
                innerImage {
                    ...ImageWithPreview
                    asset {
                        gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
                    }
                }
                frame
            }
        }
    }
`;
