import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";

const StyledContainer = styled.div`
  ${tw`flex flex-col items-center md:(mx-10) mx-4 bg-white`};
  > div {
    ${tw`mx-auto`}
  }
`;

const Iframe = styled.iframe`
  width: 100%;
  aspect-ratio: 16 / 9;
  border: none;
`;

const VideoBanner = ({ block }) => {
  return (
    <StyledContainer backgroundColor={block.backgroundColor?.hexcode}>
      <Iframe
        src={block.embeddedLink}
        allow="fullscreen;"
        title="W3Schools Free Online Web Tutorials"
      ></Iframe>
    </StyledContainer>
  );
};

export default VideoBanner;

export const query = graphql`
  fragment VideoBannerFragment on SanityVideoBanner {
    _key
    _type
    embeddedLink
  }
`;
