import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

const StyledContainer = styled.div`
  ${tw`flex flex-col items-center md:(mx-10 px-28 pt-28) mx-4 py-6 px-6 `};
  ${(props) =>
    props.noBottomPadding ? tw`pb-0` : tw`pb-28`}
  
    > div {
    ${tw`mx-auto`}
  }
`;

export default function Container({
  children,
  backgroundColor,
  textColor,
  noBottomPadding,
  ...props
}) {
  return (
    <StyledContainer
      noBottomPadding={noBottomPadding}
      style={{ backgroundColor: backgroundColor, color: textColor }}
    >
      {children}
    </StyledContainer>
  );
}
