import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

const ImageSwitcherWrapper = styled.div`
    ${tw`mb-6`}
    align-self: flex-start;
    ${tw`bg-quickSand`}
    display: inline-block;
    height: auto;
    width: auto;
    border-radius: 1rem;
    font-weight: 700;
    box-shadow: 0 0 0 2px #F0E8E0;
    .active {
    }
    .active {
        ${tw`text-quickSecondary bg-white`}
        border-radius: 1rem;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.06);
    }
    p {
        display: inline-block;
        margin: 0;
        padding: 0.5rem 1rem 0.4rem 1rem;
        line-height: 1.0;
        cursor: pointer;
        font-weight: 700;
        ${tw`text-sm`}
        @media only screen and (max-width: 768px) {
            ${tw`text-xs`}
        }
    }
`;

export default function ImageSwitcher({block, activeSlide, setActiveSlide}) {
    return (
        <ImageSwitcherWrapper>
            {block.images.map((image, index) => {
                return (
                    <p
                        key={index}
                        className={
                            index === activeSlide ? "active" : ""
                        }
                        onClick={() => {
                            setActiveSlide(index);
                        }}
                    >
                        {image.title}
                    </p>
                );
            })}
        </ImageSwitcherWrapper>
    );
}
