import React, { useEffect, useRef } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
const Container = styled.div`
    flex-wrap: wrap;
    ${tw`flex mx-12 py-32 px-32 bg-white`}
    justify-content: space-between;
    ${tw`flex items-start md:mx-10 mx-4 md:p-28 py-16 px-6 bg-white`};
    > div {
        flex-wrap: wrap;
        ${tw`flex`}
        justify-content: space-between;
        ${tw`flex items-start bg-white`};
        ${tw`mx-auto`}
        max-width: 1280px;
    }
`;

const CookieDeclaration = ({ block }) => {
    const containerRef = useRef(null);
    useEffect(() => {
        const script = document.createElement("script");

        if (typeof window !== undefined && containerRef.current !== null) {
            script.id = "CookiePolicy";
            script.src = "https://consent.cookiebot.com/8cdefa75-c813-4948-b65c-0f04fcd8841f/cd.js";
            script.defer = true;
            // script["data-culture"] = "DA";
            containerRef.current.appendChild(script);
        }
        return () => {
            containerRef.current.removeChild(script);
        };
    }, []);


    return (
        <Container>
            <div id="script" ref={containerRef}>
                <div className="inner" />
            </div>
        </Container>
    );
};

export default CookieDeclaration;

export const query = graphql`
    fragment CookieDeclarationFragment on SanityCookieDeclaration {
        _key
        _type
        country
    }
`;
