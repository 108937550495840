import React, { useState } from 'react'
import { graphql } from 'gatsby'
import styled from "styled-components";
import tw from "twin.macro";
import Accordion from './Accordion'
import Container from "../../ui/Container";

export const FAQ: React.FC = ({ block: { questions, title, backgroundColor: {hexcode} }, ...props }) => {
  const [open, setOpen] = useState<null | number>(null)
  const updateAccordions = (index: null | number) => {
    if (index == open) {
      setOpen(null)
    } else {
      setOpen(index)
    }
  }

  return (
    
      <Container backgroundColor={hexcode}>
        <h2 style={{color: "#F0E8E0", textAlign: "center"}}>
          {title}
        </h2>
        {questions.map((question, index) => {
          return (
            <Accordion
              id={index}
              key={index}
              title={question.question}
              content={question._rawAnswer}
              updateAccordions={() => updateAccordions(index)}
              open={open}
            />
          )
        })}
      </Container>
  )
}

export const query = graphql`
  fragment FaqFragment on SanityFaq {
    _key
    _type
    title
    questions {
      question
      _rawAnswer
    }
    backgroundColor {
      hexcode
    }
  }
`
