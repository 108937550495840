import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import PortableText from "@sanity/block-content-to-react";
import SanityImage from "gatsby-plugin-sanity-image";
import Button from "../../ui/Button";
const Container = styled.div`
    ${tw`flex flex-col items-center md:mx-10 mx-4 md:(p-28 py-16) py-0 bg-white`};
    > div {
        ${tw`mx-auto`}
        max-width: 1280px;
    }
`;

const InnerContainer = styled.div`
    ${tw`relative flex items-center md:(flex-row) flex-col w-full`}  
`;

const StyledPortableText = styled(PortableText)`
    text-align: left;
    ${tw`flex flex-col p-4`}
    h1 {
        margin-top: 0;
        font-size: 3rem;
        line-height: 4rem;
        letter-spacing: -0.02rem;
        font-weight: 700;
        /* Below is h4 styling from global.color.ts*/
        @media only screen and (max-width: 768px) {
            font-size: 2rem;
            line-height: 2.66rem;
            letter-spacing: -0.02rem;
            font-weight: 700;
        }
    }
    p {
        max-width: 48rem;
    }
`;

const StyledSanityImage = styled(SanityImage)`
    ${tw`md:(w-3/4) w-full`}
`;

const CtaBox = styled.div`
    background-color: #fff9f3;
    ${tw`md:(absolute p-12 w-1/2) w-full right-0 mt-auto `}
    
    > p {
        ${tw`m-0 mt-4 mb-4`}
    }
`;

const CTAOverImage = ({ block }) => {
    return (
        <Container>
            <InnerContainer>
                <CtaBox>
                    <StyledPortableText blocks={block._rawText} />
                    <div tw="flex flex-1 lg:(justify-start mb-6) justify-center mb-16">
                        <Button button={block.button} />
                    </div>
                </CtaBox>

                <StyledSanityImage
                    {...block.image.image}
                    alt={block.image.title}
                />
            </InnerContainer>
        </Container>
    );
};

export default CTAOverImage;

export const query = graphql`
    fragment CTAOverImageFragment on SanityCtaOverImage {
        _key
        _type
        _rawText(resolveReferences: { maxDepth: 10 })
        image {
            _key
            _type
            image {
                ...ImageWithPreview
            }
            title
        }
        button {
            title
            logo
            backgroundColor {
                hexcode
                colorName
            }
            targetPage {
                slug {
                    current
                }
            }
            targetPageExternal
        }
    }
`;
