import React, { useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import PortableText from "@sanity/block-content-to-react";
import { IoMdCheckmark, IoMdClose } from "react-icons/io";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useViewport } from "../../../hooks/useViewport";
import { isViewport } from "../../../helpers";

const Container = styled.div`
  ${tw`flex flex-col items-center md:(mx-10 p-28) mx-4 py-6 px-6 bg-white`};
  > div {
    ${tw`mx-auto`}
  }
`;

const InnerContainer = styled.div`
  ${tw`w-full`};
  h1 {
    ${tw`mt-0`};
  }
  p {
    margin: 0;
    padding: 0.5rem 2rem;
    border-top: 1px solid rgba(30, 30, 50, 0.05);
  }
`;

const Field = styled.p`
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  align-items: center;
`;

const FieldRowHeader = styled.span`
  line-height: 1.5;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
`;

const FieldMobile = styled.div`
  p {
    margin-bottom: 0;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const FieldMobileFirstCol = styled.p`
  p {
    margin-bottom: 0;
  }
  display: flex;
  align-items: center;
`;

const HighlightedTag = styled.div`
  border-radius: 16px;
  ${tw`bg-quickSecondary`};
  color: white;
  padding: 0.5rem;
  position: absolute;
  max-width: 78%;
  top: -1rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 1rem;
    line-height: 1rem;
  }
`;

const Column = styled.div`
  flex: 1;
`;

const ImageSwitcherWrapper = styled.div`
  ${tw`mb-6`}
  align-self: flex-start;
  ${tw`bg-quickSand`}
  display: inline-block;
  height: auto;
  width: auto;
  border-radius: 1rem;
  font-weight: 700;
  box-shadow: 0 0 0 2px #f0e8e0;
  .active {
    ${tw`text-quickSecondary bg-white`}
    border-radius: 1rem;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.06);
  }
  p {
    display: inline-block;
    margin: 0;
    padding: 0.5rem 1rem 0.4rem 1rem;
    line-height: 1;
    cursor: pointer;
    font-weight: 700;
    ${tw`text-sm`}
    @media only screen and (max-width: 768px) {
      ${tw`text-xs`}
    }
  }
`;

const Modal = styled.div`
  //${tw`bg-white rounded-2xl flex flex-col`}
  //box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.06);
  //top: calc(50vh);
  //transform: translateY(-50%);
  //width: 12rem;
  //padding: 1rem;
  z-index: 999999;
  margin: auto;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(30, 30, 50, 0.75);
  ${tw`text-white`}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(1rem);
  > div,
  > p {
    width: 80%;
    max-width: 25rem;
    &:first-child {
      //margin-top: 10%;
    }
  }
  svg {
    background-color: rgba(30, 30, 50, 1);
    padding: 0.75rem;
    border-radius: 100%;
    width: 2rem;
    height: 2rem;
    position: fixed;
    top: 1rem;
    right: 1rem;
  }
`;

const StyledButton = styled.button`
  ${tw`bg-quickPrimary text-quickSandLighter mx-auto`}
  border: none;
  font-weight: 700;
  transition: all 0.2s;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.06);
  :hover {
    box-shadow: 0 16px 20px rgba(0, 0, 0, 0.1);
  }
  :active {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
  }
  cursor: pointer;
  margin: 56px 2rem;
  padding: 0.5rem 2rem;
`;

const Pricing = ({ block }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const viewport = useViewport();
  const isPhone = isViewport(viewport, [
    "PHONE_ONLY",
    "TABLET_PORTRAIT_UP",
    "TABLET_LANDSCAPE_UP",
  ]);
  const [modalOpen, setModalOpen] = useState<any[] | null>(null);
  // const [modalOpenDesktop, setModalOpenDesktop] = useState<number|null>(null);

  const highlightedCol = block.columns.find((item) => item.highlighted);

  const openModal = (arr: any[]) => {
    setModalOpen(arr);
  };

  const navigateToLeadForm = (urlParameters: string) => {
    const anchor = document.getElementById("lead-form");
    anchor?.scrollIntoView();

    const urlWithPath = location.origin + location.pathname;
    window.history.pushState(
      null,
      "",
      urlWithPath + (urlParameters ? urlParameters : "")
    );
  };

  // const openModalDesktop = (num: number) => {
  //     setModalOpenDesktop(num+1)
  // }

  return (
    <Container>
      {modalOpen && (
        <Modal
          onClick={() => {
            setModalOpen(null);
          }}
        >
          <div tw="flex justify-between">
            <h3>{block.rows[modalOpen[0]].title}</h3>
            <IoMdClose
              onClick={() => {
                setModalOpen(null);
              }}
              tw="cursor-pointer text-white"
            />
          </div>
          <PortableText blocks={block.rows[modalOpen[0]]._rawInfoBox} />
          {modalOpen[1] === 0 && (
            <PortableText blocks={block.rows[modalOpen[0]]?._rawInfoBoxCol1} />
          )}
          {modalOpen[1] === 1 && (
            <PortableText blocks={block.rows[modalOpen[0]]?._rawInfoBoxCol2} />
          )}
          {modalOpen[1] === 2 && (
            <PortableText blocks={block.rows[modalOpen[0]]?._rawInfoBoxCol3} />
          )}
          {modalOpen[1] === null && block.rows[modalOpen[0]]?._rawInfoBoxCol1 && (
            <>
              <p tw="mb-0">{block.columns[0]?.title}:</p>
              <PortableText
                blocks={block.rows[modalOpen[0]]?._rawInfoBoxCol1}
              />
            </>
          )}
          {modalOpen[1] === null && block.rows[modalOpen[0]]?._rawInfoBoxCol2 && (
            <>
              <p tw="mb-0">{block.columns[1]?.title}:</p>
              <PortableText
                blocks={block.rows[modalOpen[0]]?._rawInfoBoxCol2}
              />
            </>
          )}
          {modalOpen[1] === null && block.rows[modalOpen[0]]?._rawInfoBoxCol3 && (
            <>
              <p tw="mb-0">{block.columns[2]?.title}:</p>
              <PortableText
                blocks={block.rows[modalOpen[0]]?._rawInfoBoxCol3}
              />
            </>
          )}
        </Modal>
      )}

      {/* {modalOpenDesktop && block.rows[modalOpenDesktop-1] && <Modal>
                <div tw="flex justify-between">
                    <p>{block.rows[modalOpenDesktop-1].title}</p> 
                    <IoMdClose
                        onClick={() => {
                            setModalOpenDesktop(null)
                        }}
                        tw="cursor-pointer text-quickDark"
                        size={18}
                    />
                </div>
                <PortableText blocks={block.rows[modalOpenDesktop-1]._rawInfoBox} />
            </Modal>} */}
      {!isPhone ? (
        <InnerContainer>
          <h1 tw="mb-24">{block.title}</h1>
          <div>
            <Column
              style={{
                display: "flex",
                flex: 1,
                // justifyContent: "center",
              }}
            >
              <div>
                <div style={{ height: "273px" }}></div>
                {block.rows.map((row, key) => {
                  let x = block.rows[key];
                  let bool =
                    x.example1line2 || x.example2line2 || x.example3line2;
                  let height = bool ? "8rem" : "2rem";
                  return (
                    <Field
                      key={key}
                      style={{
                        width: "18rem",
                        paddingLeft: "0",
                        height: height,
                      }}
                    >
                      <FieldRowHeader>{row.title}</FieldRowHeader>
                      {(row._rawInfoBox || row._rawInfoBoxCol1) && (
                        <AiOutlineInfoCircle
                          style={{
                            cursor: "pointer",
                            marginLeft: ".5rem",
                          }}
                          onClick={() => openModal([key, null])}
                        />
                      )}
                    </Field>
                  );
                })}
              </div>
              {block.columns.map((col, index) => {
                const bg =
                  highlightedCol?.title === col.title
                    ? "rgba(128, 141, 255, 0.15)"
                    : "#F0E8E0";
                return (
                  <Column
                    key={index}
                    style={{
                      backgroundColor: bg,
                      borderRadius: "16px",
                      position: "relative",
                      margin: "1px",
                      cursor: "pointer",
                    }}
                  >
                    {highlightedCol?.title === col.title && (
                      <HighlightedTag>
                        <span>{col.highlightedText}</span>
                      </HighlightedTag>
                    )}
                    <div
                      style={{
                        paddingTop: "2rem",
                        height: "240px",
                      }}
                    >
                      <Field
                        style={{
                          fontWeight: 700,
                          fontSize: "32px",
                          border: "none",
                        }}
                      >
                        {col.title}
                      </Field>
                      {col.withoutTable ? (
                        <Field
                          style={{
                            border: "none",
                            fontSize: "1.8rem",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "1rem",
                              opacity: 0.5,
                            }}
                          >
                            {col.subtitle}
                          </span>
                        </Field>
                      ) : (
                        <Field
                          style={{
                            border: "none",
                            fontSize: "1.8rem",
                          }}
                        >
                          {col.pricePerMonth}{" "}
                          <span
                            style={{
                              marginLeft: ".5rem",
                              fontSize: "1rem",
                            }}
                          >
                            {col.currency}
                          </span>
                        </Field>
                      )}

                      <StyledButton
                        onClick={() => {
                          navigateToLeadForm(col.formPopulation);
                        }}
                      >
                        {col.buttonText}
                      </StyledButton>
                    </div>

                    {col.withoutTable ? (
                      <div>
                        <p>{col.description}</p>
                      </div>
                    ) : (
                      block.rows.map((row, key) => {
                        // todo: get number of row => change height
                        let x = block.rows[key];
                        let bool =
                          x.example1line2 || x.example2line2 || x.example3line2;
                        let height = bool ? "8rem" : "2rem";

                        return (
                          <Field style={{ height: height }}>
                            {row[`example${index + 1}`]?.toLowerCase() ==
                              "check" && <IoMdCheckmark />}
                            {row[`example${index + 1}`]?.toLowerCase() ==
                            "check"
                              ? ""
                              : row[`example${index + 1}`]}
                            <br style={{ margin: ".5rem" }} />
                            {row[`example${index + 1}line2`]?.toLowerCase() ==
                              "check" && <IoMdCheckmark />}
                            {row[`example${index + 1}`]?.toLowerCase() ==
                            "check"
                              ? ""
                              : row[`example${index + 1}line2`]}
                          </Field>
                        );
                      })
                    )}
                  </Column>
                );
              })}
            </Column>
          </div>
        </InnerContainer>
      ) : (
        <>
          <h1>{block.title}</h1>

          <ImageSwitcherWrapper>
            {block.columns.map((column, index) => {
              return (
                <p
                  key={index}
                  className={index === activeSlide ? "active" : ""}
                  onClick={() => {
                    setActiveSlide(index);
                  }}
                >
                  {column.title}
                </p>
              );
            })}
          </ImageSwitcherWrapper>
          {block.columns.map((col, index) => {
            const bg = "white";
            if (activeSlide === index)
              return (
                <Column
                  key={index}
                  tw="w-full"
                  style={{
                    backgroundColor: bg,
                    borderRadius: "16px",
                    position: "relative",
                    maxWidth: "24rem",
                    cursor: "pointer",
                  }}
                >
                  {col.withoutTable ? (
                    <Field
                      tw="py-4 w-auto mx-auto"
                      style={{
                        border: "none",
                        fontSize: "1.8rem",
                      }}
                    >
                      <span
                        tw="py-4 w-auto mx-auto"
                        style={{
                          fontSize: "1rem",
                          opacity: 0.5,
                        }}
                      >
                        {col.subtitle}
                      </span>
                    </Field>
                  ) : (
                    <div tw="py-4 w-auto mx-auto flex flex-col items-center">
                      <div tw="mx-auto w-auto flex">
                        <p
                          style={{
                            border: "none",
                            fontSize: "1.8rem",
                          }}
                        >
                          {col.pricePerMonth}{" "}
                        </p>
                        <p
                          style={{
                            marginLeft: ".5rem",
                            fontSize: "1rem",
                          }}
                        >
                          {col.currency}
                        </p>
                      </div>
                      <StyledButton
                        onClick={() => {
                          navigateToLeadForm(col.formPopulation);
                        }}
                      >
                        {col.buttonText}
                      </StyledButton>
                    </div>
                  )}
                  {col.withoutTable ? (
                    <div tw="flex flex-col items-center">
                      <p>{col.description}</p>
                      <StyledButton
                        onClick={() => {
                          navigateToLeadForm(col.formPopulation);
                        }}
                      >
                        {col.buttonText}
                      </StyledButton>
                    </div>
                  ) : (
                    block.rows.map((row, key) => {
                      // todo: get number of row => change height
                      let x = block.rows[key];
                      let bool =
                        x.example1line2 || x.example2line2 || x.example3line2;

                      return (
                        <div tw="flex w-full justify-between">
                          <FieldMobileFirstCol>
                            {row.title}
                            {(row._rawInfoBox || row._rawInfoBoxCol1) && (
                              <AiOutlineInfoCircle
                                style={{
                                  cursor: "pointer",
                                  marginLeft: ".5rem",
                                }}
                                onClick={() => openModal([key, index])}
                              />
                            )}
                          </FieldMobileFirstCol>
                          <FieldMobile tw="flex-1 ml-auto">
                            {row[`example${index + 1}`]?.toLowerCase() ==
                              "check" && <IoMdCheckmark />}
                            {row[`example${index + 1}`]?.toLowerCase() ==
                            "check" ? (
                              <p></p>
                            ) : (
                              <p>{row[`example${index + 1}`]}</p>
                            )}
                            <br style={{ margin: ".5rem" }} />
                            {row[`example${index + 1}line2`]?.toLowerCase() ==
                              "check" && <IoMdCheckmark />}
                            {row[`example${index + 1}`]?.toLowerCase() ==
                            "check" ? (
                              <p></p>
                            ) : (
                              <p>{row[`example${index + 1}line2`]}</p>
                            )}
                          </FieldMobile>
                        </div>
                      );
                    })
                  )}
                </Column>
              );
          })}
        </>
      )}
    </Container>
  );
};

export default Pricing;

export const query = graphql`
  fragment PricingFragment on SanityPricing {
    _key
    _type
    title
    columns {
      highlighted
      highlightedText
      pricePerMonth
      title
      currency
      withoutTable
      subtitle
      description
      buttonText
      formPopulation
    }
    rows {
      _rawInfoBox
      _rawInfoBoxCol1
      _rawInfoBoxCol2
      _rawInfoBoxCol3
      example1
      example2
      example3
      example1line2
      example2line2
      example3line2
      title
    }
  }
`;
