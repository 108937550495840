import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";

const PhoneContainer = styled.div`
  ${tw`flex bg-white md:mx-10 mx-4 pb-16`};
  flex: 1;
  display: flex;
  justify-content: space-evenly;

  @media only screen and (max-width: 768px) {
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-coordinate: 0 0;
    scroll-snap-points-x: repeat(100%);
    scroll-snap-type: x mandatory;
    justify-content: flex-start;
    -webkit-overflow-scrolling: touch;
    section {
      flex: 0 0 calc(100vw - 30px);
      scroll-snap-align: start;
    }
  }
`;

const Card = styled.div`
  ${tw`p-4 m-4`};
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
  border-radius: 1rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.06);
  > img {
    ${tw`mb-4`};
    width: 50px;
    height: 50px;
  }
  > div {
    display: flex;
    align-items: space-between;
    div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    img {
      width: 50px;
      height: 50px;
    }
  }
`;
const QuoteSign = styled.span`
  margin: 0;
  padding: 0;
  font-size: 100px;
  font-style: italic;
  position: relative;
  top: 20px;
  left: -18px;
  ${tw`text-quickPrimary top-6 `};
`;

const Title = styled.p`
  margin: 0;
  padding: 0;
  ${tw`pl-4 text-quickSecondary text-sm`};
`;

const Name = styled.p`
  margin: 0;
  padding: 0;
  ${tw`pl-4 font-bold`};
`;

const Testimonials = ({ block }) => {
  return (
    <PhoneContainer>
      {block.testimonials.map((testimonial, index) => {
        return (
          <section key={index}>
            <Card>
              <QuoteSign>"</QuoteSign>
              <p>"{testimonial.quote}"</p>
              <div>
                <img src={testimonial.image?.asset?.url} alt="" />
                <div>
                  <Title>{testimonial.title?.toUpperCase()}</Title>
                  <Name>{testimonial.name}</Name>
                </div>
              </div>
            </Card>
          </section>
        );
      })}
    </PhoneContainer>
  );
};

export default Testimonials;

export const query = graphql`
  fragment TestimonialsFragment on SanityTestimonials {
    _key
    _type
    testimonials {
      image {
        asset {
          url
        }
      }
      name
      quote
      title
    }
  }
`;
