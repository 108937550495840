import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import SanityImage from "gatsby-plugin-sanity-image";

const Container = styled.div`
    min-width: 335px;
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    overflow: hidden;
    margin-bottom: 2rem;
    @media only screen and (max-width: 768px) {
        min-width: 355px;
        margin: 2rem;
    }
`;

const Tag = styled.div`
    z-index: 1;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 1rem;
    ${tw`text-quickDark bg-quickSandLighter m-6`}
`;

const CenteredFeature = styled.div`
    height: 6rem;
    ${tw`flex flex-col justify-center`}
    h4 {
        text-align: center;
        margin: 0;
    }
    p {
        margin: 0;
    }
`;

const CaseCard = ({ card }) => {
    const { tag, image, title, venues, employees, devices } = card;
    return (
        <Container>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    flex: 1,
                    position: "relative",
                    backgroundColor:
                        "linear-gradient(10.97deg, rgba(0, 0, 0, 0.3) 6.31%, rgba(0, 0, 0, 0.05) 91.56%)",
                }}
            >
                <SanityImage
                    style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        objectFit: "cover",
                        opacity: ".75",
                    }}
                    {...image}
                    alt={title}
                />
                <Tag>
                    <p style={{ margin: 0 }}>{tag}</p>
                </Tag>
                <h4
                    tw="text-quickSandLighter"
                    style={{ zIndex: 1, margin: "1.5rem" }}
                >
                    {title}
                </h4>
            </div>
            <div
                tw="text-quickSandLighter"
                style={{
                    display: "flex",
                    backgroundColor: "#808DFF",
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                    justifyContent: "space-between",
                }}
            >
                <CenteredFeature>
                    <h4>{venues}</h4>
                    <p>Venues</p>
                </CenteredFeature>
                <CenteredFeature>
                    <h4>{employees}</h4>
                    <p>Employees</p>
                </CenteredFeature>
                <CenteredFeature>
                    <h4>{devices}</h4>
                    <p>Devices</p>
                </CenteredFeature>
            </div>
        </Container>
    );
};

export default CaseCard;
