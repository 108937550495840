import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import SanityImage from "gatsby-plugin-sanity-image";
import PortableText from "@sanity/block-content-to-react";
import { MdChevronRight } from "react-icons/md";
import getColor from "../../../helpers/getColor";
import ButtonNormal from "../../ui/ButtonNormal";
import Container from "../../ui/Container";
import ImageSwitcher from "../../ui/ImageSwitcher";


const StyledLink = styled(Link)`
    color: #808dff;
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 1rem;
`;

const StyledPortableText = styled(PortableText)`
    text-align: left;
    max-width: 768px;
    ${tw`flex flex-col`}
`;

const TextBannerWithBackground = ({ block }) => {
    const [activeSlide, setActiveSlide] = useState(0);
    
    const serializers = {
        types: {
            internalLink: ({ node }) => {
                return (
                    <div>
                        <StyledLink to={"/" + node.page?.slug.current}>
                            <span>{node.linkText}</span>
                            <MdChevronRight size={36} color="#808DFF" />
                        </StyledLink>
                    </div>
                );
            },
            imageSwitch: ({ node }) => {
                return <ImageSwitcher block={block} activeSlide={activeSlide} setActiveSlide={(i) => {setActiveSlide(i)}}/>;
            },
            buttons: ({ node }) => {
                return (
                    <div tw="flex md:mr-4 md:flex-row flex-col flex-1 lg:(justify-start) justify-between">
                        {node.buttons.map((button, index) => {
                            return (
                                <div tw="md:mr-4">
                                    <ButtonNormal
                                        tw="md:mr-4"
                                        button={button}
                                        key={index}
                                    />
                                </div>
                            );
                        })}
                    </div>
                );
            },    
        },
    };

    return (
        <Container 
            textLeft={block.textLeft} 
            backgroundColor={block.backgroundColor?.hexcode}
            textColor={getColor(block.backgroundColor?.colorName)} 
            style={{
                overflow: "hidden",
                maxWidth: "100%",
            }}
        >
            <StyledPortableText
                blocks={block._rawText}
                serializers={serializers}
            />
         
        </Container>
    );
};

export default TextBannerWithBackground;

export const query = graphql`
    fragment TextBannerWithBackgroundFragment on SanityTextBannerWithBackground {
        _key
        _type
        _rawText(resolveReferences: { maxDepth: 10 })
        backgroundColor {
            hexcode
            colorName
        }
    }
`;
