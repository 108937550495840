import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import SanityImage from "gatsby-plugin-sanity-image";
import Tag from "../../../components/ui/Tag";
import { convertDate } from "../../../helpers/convertDate.ts";
import { useViewport } from "../../../hooks/useViewport";
import { isViewport } from "../../../helpers";

// maintain: Here you have to add a new country
const academy =  {
    da: "akademi",
    de: "akademie",
    us: "academy",
    no: "akademi"
}

const displayCountry = {
    us: "en",
    da: "dk",
    no: "no",
    de: "de",
};

const Container = styled.div`
    flex-wrap: wrap;
    ${tw`flex mx-12 py-32 px-32 bg-white`}
    justify-content: space-between;
    ${tw`flex items-start md:mx-10 mx-4 md:p-28 py-16 px-6 bg-white`};
    > div {
        flex-wrap: wrap;
        ${tw`flex`}
        justify-content: space-between;
        ${tw`flex items-start bg-white`};
        ${tw`mx-auto`}
        max-width: 1280px;
    }
`;

const Card = styled.div`
    width: 350px;
    overflow: hidden;
    ${tw`mb-16`}

    img {
        height: 300px;
        width: auto;
        ${tw`mb-8`}
    }
    
    @media only screen and (max-width: 768px) {
        overflow: hidden;
        ${tw`mb-16`}
        width: auto;


        img {
            height: auto;
            width: 100%;
            ${tw`mb-8`}
        }
    }

    a {
        ${tw`text-quickDark`}
    }
    a:visited {
        ${tw`text-quickDark`}
    }
`;

const Wrapper = styled.div`
    position: relative;
`;

const CardTitle = styled.p`
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: -0.01rem;
    font-weight: 700;
    ${tw`mt-0 mb-3`}
`;

const StyledSanityImage = styled(SanityImage)`
    width: 100%;
`;

const ArticleGrid = ({ block }) => {
    const {
        allSanityArticle: { edges: node },
    } = useStaticQuery(graphql`
        query StaticArticleGrid {
            allSanityArticle(sort: { fields: date, order: DESC }) {
                edges {
                    node {
                        title
                        slug {
                            current
                        }
                        country
                        date
                        tags
                        image {
                            image {
                                ...ImageWithPreview
                            }
                            title
                        }
                    }
                }
            }
        }
    `);
    const viewport = useViewport();
    const isPhone = isViewport(viewport, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"]);

    return (
        <Container>
            <div>
                {node.map((article, index) => {
                    const { title, date, tags, image, slug, country } = article.node;
                    const imageUrl = image?.image;
                    const currentSlug = slug?.current;
                    if (currentSlug && imageUrl && block.country === country) {
                        return (
                            <Card key={index}>
                                {/* todo: implement multilanguage */}
                                <a
                                    href={`/${displayCountry[country]}/${academy[country]}/${currentSlug}`}
                                    // target="_blank"
                                >
                                    <Wrapper>
                                        <StyledSanityImage
                                            {...imageUrl}
                                            alt={image.title}
                                        ></StyledSanityImage>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                marginBottom: "1.5rem"
                                            }}
                                        >
                                            <Tag
                                                color="#808DFF"
                                                backgroundColor="#ECEEFF"
                                            >
                                                {tags[0]}
                                            </Tag>
                                            <span style={{ marginLeft: "1rem", fontWeight: 400 }}>
                                                {convertDate(date, country)}
                                            </span>
                                        </div>
                                    </Wrapper>
                                    <CardTitle>{title}</CardTitle>
                                </a>
                            </Card>
                        );
                    }
                })}
            </div>
        </Container>
    );
};

export default ArticleGrid;

export const query = graphql`
    fragment ArticleGridFragment on SanityArticleGrid {
        _key
        _type
        country
    }
`;
