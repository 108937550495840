import React, { useState } from 'react'
import styled from 'styled-components'
import tw from "twin.macro";
import Chevron from './Chevron'
import PortableText from "@sanity/block-content-to-react";

const Section = styled.div`
    display: flex;
    flex-direction: column;
    ${tw`text-quickSand mx-auto md:w-2/3`}
    border-bottom: 1px solid #F0E8E0;

`;
const Button = styled.div`
    ${tw`text-quickSand`}
    cursor: pointer;
    display: flex;
    align-items: center;
`;
const Title = styled.div`
  font-weight: 400;
  max-width: 90%;
  padding: 1rem;
  padding-left: 0;
`
const Content = styled.div`
    overflow: hidden;
    transition: max-height 0.6s ease;
    p {
      padding: 1rem;
      padding-top: 0rem;
    }
    a {
      ${tw`text-quickSecondary`}
    }
`;

const StyledChevron = styled(Chevron)`
  transform: rotate(90deg);
`
const StyledChevronTurned = styled(Chevron)`
  transform: rotate(-90deg);
`

function Accordion(props) {
  const { open, updateAccordions, id } = props
  const [active, setActive] = useState(false)

  if (open !== id) {
    if (active !== false) {
      setActive(false)
    }
  }

  function toggleAccordion() {
    updateAccordions()
    setActive(!active)
  }

  return (
    <Section>
      <Button className={`accordion ${setActive}`} onClick={toggleAccordion}>
        <Title>{props.title}</Title>
        {active ? (
          <StyledChevronTurned width={10} fill={"#F0E8E0"} />
        ) : (
          <StyledChevron width={10} fill={"#F0E8E0"} />
        )}
      </Button>
      {active ? (
        <Content>
          <PortableText blocks={props.content} />
        </Content>
      ) : (
        <Content style={{ maxHeight: "0px", color: "#F0E8E0" }}>
          <PortableText blocks={props.content} />
        </Content>
      )}
    </Section>
  );
}

export default Accordion
