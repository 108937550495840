import React from "react";
import { Helmet } from "react-helmet"
import { graphql, PageProps } from "gatsby";
import "twin.macro";
import { Layout } from "../components/Layout/index.tsx";
// import { SEO } from "components/SEO";
// import FramedImage  from "../components/content/FramedImage";
import PageBuilder from "../components/PageBuilder";
// import { linkResolver } from "utils/linkResolver";

const Page: React.FC<PageProps<any>> = ({ data: { sanityPage }, ...props }) => {
  const { title, country, pageBuilder, metaData, groupName, socialSharingImage } = sanityPage;
  // const url = linkResolver({ _type, slug });

  const meta = metaData !== null ? metaData : {
    metaTitle: title,
    metaDescription: null,
    metaRobots: true,
  }
  
  const pageTitle = meta.metaTitle + " | Quickorder";

  return (
    <Layout language={country} groupName={groupName} pageBuilder={pageBuilder}>
      <Helmet>
        <title>{pageTitle}</title>
        {meta.metaDescription !== null &&
        <meta name="description" content={meta.metaDescription}></meta>}
        <meta name="robots" content={meta.metaRobots === false ? "noindex" : ""}></meta>
        {socialSharingImage && <meta name="image" property="og:image" content={socialSharingImage.asset.url} />}
      </Helmet>
      <div>{/* <FramedImage /> */}</div>
      <PageBuilder blocks={pageBuilder} {...props} />
    </Layout>
  );
};

export default Page;

// maintain: Here you have to add a new UI component
export const query = graphql`
  query Page($slug: String!) {
    sanityPage(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      country
      groupName
      metaData {
        metaTitle
        metaDescription
        metaRobots
      }
      socialSharingImage {
        ...ImageWithPreview
        asset {
          url
        }
      }
      pageBuilder {
        ... on SanityHero {
          ...HeroFragment
        }
        ... on SanityHeroCentered {
          ...HeroCenteredFragment
        }
        ... on SanityCta {
          ...CTAFragment
        }
        ... on SanityTestimonials {
          ...TestimonialsFragment
        }
        ... on SanityLeadForm {
          ...LeadFormFragment
        }
        ... on SanityLogoWithText {
          ...LogoWithTextFragment
        }
        ... on SanityHorizontalRule {
          ...HorizontalRuleFragment
        }
        ... on SanityHubspotForm {
          ...HubspotFormFragment
        }
        ... on SanityArticleGrid {
          ...ArticleGridFragment
        }
        ... on SanityCookieDeclaration {
          ...CookieDeclarationFragment
        }
        ... on SanityFeatureGrid {
          ...FeatureGridFragment
        }
        ... on SanityCardGrid {
          ...CardGridFragment
        }
        ... on SanityRichText {
          ...RichTextFragment
        }
        ... on SanitySignup {
          ...SignupFragment
        }
        ... on SanityFaq {
          ...FaqFragment
        }
        ... on SanityPricing {
          ...PricingFragment
        }
        ... on SanityImageBanner {
          ...ImageBannerFragment
        }
        ... on SanityLogoBanner {
          ...LogoBannerFragment
        }
        ... on SanityTextBanner {
          ...TextBannerFragment
        }
        ... on SanityTextBannerWithBackground {
          ...TextBannerWithBackgroundFragment
        }
        ... on SanityTextWithTwoImages {
          ...TextWithTwoImagesFragment
        }
        ... on SanityCtaOverImage {
          ...CTAOverImageFragment
        }
        ... on SanityVideoBanner {
          ...VideoBannerFragment
        }
        ... on SanityClientCases {
          ...ClientCasesFragment
        }
      }
    }
  }
`;
