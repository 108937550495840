import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import PortableText from "@sanity/block-content-to-react";
import { MdChevronRight } from "react-icons/md";
import { IoMdCheckmark } from "react-icons/io";
import { useViewport } from "../../../hooks/useViewport";
import { isViewport } from "../../../helpers";
import ImageSwitcher from "../../ui/ImageSwitcher";

const StyledPortableText = styled(PortableText)`
    ${tw`flex md:flex-1 flex-col justify-center text-left md:w-1/2 text-quickDark`}
    p, h1, h2, h3 {
        max-width: 44rem;
    }
    @media only screen and (max-width: 768px) {
        min-height: 0;
        padding-bottom: 0;
    }
`;

const ImageWrapper = styled.div`
    ${tw`flex md:(w-1/2 mx-0)`}
    display: flex;
    align-items: center;
`

const StyledLink = styled(Link)`
    text-decoration: none; 
    ${tw`flex items-center md:mb-10 mb-6 h-4 text-quickSecondary`}
    svg {
      transition: transform 0.2s;
      transform: translateX(0);
    }
    &:hover {
      svg {
        transform: translateX(0.5rem);
      }
    }
    &:active {
      opacity: 0.85;
    }
`;

const StyledExternalLink = styled.a`
    text-decoration: none;
    ${tw`flex items-center md:mb-10 mb-6 h-4 text-quickSecondary`}
    svg {
      transition: transform 0.2s;
      transform: translateX(0);
    }
    &:hover {
      svg {
        transform: translateX(0.5rem);
      }
    }
    &:active {
      opacity: 0.85;
    }
`;
const StyledContainer = styled.div`
  ${tw`flex bg-white md:mx-10 mx-4`};
  flex-direction: ${({ phone, textLeft }) =>
    textLeft && !phone ? "row" : phone ? "column" : "row-reverse"};
  .text-section {
    ${tw`md:py-28 pt-8`}
    padding-left: ${({ phone, textLeft }) =>
      textLeft && !phone ? "6.8rem" : phone ? "1.5rem" : "0rem"};
    padding-right: ${({ phone, textLeft }) =>
      textLeft && !phone ? "0rem" : phone ? "1.5rem" : "6.8rem"};
  }
`;

const CTA = ({ block }) => {
    const [activeSlide, setActiveSlide] = useState(0);
    const viewport = useViewport();
    const phone = isViewport(viewport, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"]);

    const serializers = {
        types: {
            internalLink: ({ node }) => {
                return (
                    <div>
                        <StyledLink to={"/" + node.page?.slug.current}>
                            <span>{node.linkText}</span>
                            <MdChevronRight size={36} color="#808DFF" />
                        </StyledLink>
                    </div>
                );
            },
            externalLink: ({ node }) => {
                return (
                    <div>
                        <StyledExternalLink
                            href={node.linkAddress}
                            target="_blank"
                            rel="noopener"
                        >
                            <span>{node.linkText}</span>
                            <MdChevronRight size={36} color="#808DFF" />
                        </StyledExternalLink>
                    </div>
                );
            },
            checkpoint: ({ node }) => {
                return (
                    <div tw="flex items-center md:mb-10 mb-6">
                        <div>
                            <IoMdCheckmark size={32} tw="mr-4" />
                        </div>
                        <p style={{ margin: 0 }}>{node.text}</p>
                    </div>
                );
            },

            imageSwitch: ({ node }) => {
                return (
                    <ImageSwitcher block={block} activeSlide={activeSlide} setActiveSlide={(i) => {setActiveSlide(i)}}/>
                );
            },
            description: ({ node }) => {
                return (
                    <>
                        {block.images.map((image, index) => {
                            return (
                                <>
                                    {image && index === activeSlide ? (
                                        <StyledPortableText
                                            blocks={image._rawDescription}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </>
                            );
                        })}
                    </>
                );
            },
        },
    };

    return (
        <StyledContainer textLeft={block.textLeft} phone={phone}>
                <StyledPortableText
                    blocks={block._rawText}
                    serializers={serializers}
                    className="text-section"
                />
                <ImageWrapper>
                {block.images.map((image, index) => {
                        const elem =
                            image && index === activeSlide ? (
                                <img src={image.image.asset.url} alt="" />
                            ) : null;
                         
                        return elem;
                    })}

                </ImageWrapper>
        </StyledContainer>
    );
};

export default CTA;

export const query = graphql`
    fragment CTAFragment on SanityCta {
        _key
        _type
        textLeft
        _rawText(resolveReferences: { maxDepth: 10 })
        images {
            ... on SanityImageWithTitle {
                _key
                _type
                image {
                    ...ImageWithPreview
                    asset {
                        url
                    }
                }
                title
            }
            ... on SanityImageWithTitleAndDescription {
                _key
                _type
                image {
                    ...ImageWithPreview
                    asset {
                        url
                    }
                }
                title
                _rawDescription
            }
        }
    }
`;
