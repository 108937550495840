import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import SanityImage from "gatsby-plugin-sanity-image";
import PortableText from "@sanity/block-content-to-react";
import ButtonNormal from "../../ui/ButtonNormal";
import { useViewport } from "../../../hooks/useViewport";
import { isViewport } from "../../../helpers";

const StyledContainer = styled.div`
    ${tw`md:mx-10 mx-4`};
}
    > div {
        ${tw`mx-auto`}
        ${tw`flex flex-row items-center`};
    }
`;

const StyledPortableText = styled(PortableText)`
    ${tw`text-quickSandLighter`}
    padding: 6.8rem 0 6.8rem 6.8rem;
    ${props => props.withImage ? tw`md:w-1/2` : tw`md:w-full`}
    @media only screen and (max-width: 768px) {
        padding: 1.5rem 1.5rem 0 1.5rem;
    }
`;
const StyledSanityImage = styled(SanityImage)`
    z-index: 1;
    ${tw`md:w-1/2`}
    height: auto;
    min-height: auto;
    border: none;
`;

const serializers = {
    types: {
        buttons: ({ node }) => {
            return (
                <div tw="flex md:mr-4 flex-1 md:flex-row flex-col lg:(justify-start) justify-between">
                    {node.buttons.map((button, index) => {
                        return (
                            <div tw="md:mr-4">
                                <ButtonNormal
                                    tw="md:mr-4"
                                    button={button}
                                    key={index}
                                />
                            </div>
                        );
                    })}
                </div>
            );
        },
    },
};

const Hero = ({ block }) => {
    const viewport = useViewport();
    const phone = isViewport(viewport, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"]);
    const {
        backgroundColor: { hexcode },
    } = block;

    return (
        <>
            <StyledContainer  style={{ backgroundColor: hexcode}}>
                <div tw="flex lg:flex-row flex-col  relative">
                    <StyledPortableText
                        blocks={block._rawText}
                        serializers={serializers}
                        withImage={block.image}
                    />

                    {!phone && block.image && <StyledSanityImage
                        {...block.image}
                        alt={block.alternativeText}
                    ></StyledSanityImage>}
                </div>
                {phone && block.image && <SanityImage
                    {...block.image}
                    alt={block.alternativeText}
                ></SanityImage>}
            </StyledContainer>
        </>
    );
};

export default Hero;

export const query = graphql`
    fragment HeroFragment on SanityHero {
        _key
        _type
        _rawText(resolveReferences: { maxDepth: 10 })
        backgroundColor {
            hexcode
            colorName
        }
        image {
            ...Image
        }
        alternativeText
    }
`;
