import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import { MdChevronRight } from "react-icons/md";
import PortableText from "@sanity/block-content-to-react";
import Container from "../../ui/Container";
import ImageSwitcher from "../../ui/ImageSwitcher";
import LinkWithArrow from "../../ui/LinkWithArrow";
import getColor from "../../../helpers/getColor";

const StyledPortableText = styled(PortableText)`
    ${tw`flex flex-1 flex-col md:mx-12`}
    p, h1, h2, h3 {
      max-width: 44rem;
    }
`;

const Scroll = styled.div`
    overflow: auto;
    white-space: nowrap;
    width: 100%;
    ${tw`mt-8`}
`;

const StyledExternalLink = styled.a`
    text-decoration: none;
    ${tw`flex items-center md:mb-10 mb-6 h-4 text-quickSecondary`}
`;

const RichText = ({ block }) => {
    const serializers = {
        types: {
            internalLink: ({ node }) => {
                return (
                    <LinkWithArrow
                        to={"/" + node.page?.slug.current}
                        text={node.linkText}
                    />
                );
            },
            externalLink: ({ node }) => {
                return (
                    <div>
                        <StyledExternalLink
                            href={node.linkAddress}
                            target="_blank"
                            rel="noopener"
                        >
                            <span>{node.linkText}</span>
                            <MdChevronRight size={36} color="#808DFF" />
                        </StyledExternalLink>
                    </div>
                );
            },
            imageSwitch: ({ node }) => {
                return <ImageSwitcher block={block} activeSlide={activeSlide} setActiveSlide={(i) => {setActiveSlide(i)}}/>;
            },
            image: ({ node }) => {
                return (
                    <Scroll>
                        <img
                            src={node.asset.url}
                            alt=""
                        />
                    </Scroll>
                );
            },
        },
    };
    return (
        <Container backgroundColor={block.backgroundColor?.hexcode} noBottomPadding={block.noBottomPadding}>
            <div
                style={{
                    overflow: "hidden",
                    maxWidth: "100%",
                    color: getColor(block.backgroundColor?.colorName),
                }}
            >
                <StyledPortableText
                    blocks={block._rawText}
                    serializers={serializers}
                />
            </div>
        </Container>
    );
};

export default RichText;

export const query = graphql`
    fragment RichTextFragment on SanityRichText {
        _key
        _type
        _rawText(resolveReferences: { maxDepth: 10 })
        backgroundColor {
            hexcode
            colorName
        }
        noBottomPadding
    }
`;
