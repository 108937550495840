import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import SanityImage from "gatsby-plugin-sanity-image";
import phone from "../../../../images/phone-bg.png";
import tablet from "../../../../images/tablet-bg.png";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Container = styled.div`
  width: 16rem;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.06);
  :hover {
    cursor: pointer;
    box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.1);
  }
  ${tw`p-6 pb-0 relative`}
  border-radius: 25px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 2rem;

  @media only screen and (max-width: 1048px) {
    min-width: 350px;
    margin: 2rem;
  }
`;

const StyledSanityImage = styled(SanityImage)`
  ${tw`mx-auto relative mt-16`}
`;

const FeatureCard = ({ feature }) => {
  const { framedImage, title, subtitle, link, externalLink } = feature;

  const Card = () => {
    return (
      <>
        <div>
          <h4 tw="m-0">{title}</h4>
          <p>{subtitle}</p>
        </div>
        {framedImage && (
          <div>
            {framedImage.frame == "phone" && (
              <div
                tw="mx-auto"
                style={{
                  backgroundImage: `url(${phone})`,
                  backgroundSize: "cover",
                  height: 150,
                  width: "50%",
                }}
              >
                <StyledSanityImage
                  {...framedImage.innerImage}
                  alt={title}
                  style={{ width: "92%" }}
                  tw="top-1 rounded-2xl rounded-b-none"
                />
              </div>
            )}
            {framedImage.frame == "tablet" && (
              <div
                style={{
                  backgroundImage: `url(${tablet})`,
                  backgroundSize: "cover",
                  height: 150,
                }}
              >
                <StyledSanityImage
                  {...framedImage.innerImage}
                  alt={title}
                  style={{ width: "94%" }}
                  tw="top-2"
                />
              </div>
            )}
            {framedImage.frame == "none" && (
              <div
                style={{
                  height: "240px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <GatsbyImage
                  image={framedImage.innerImage.asset.gatsbyImageData}
                  alt={title}
                />
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <Container>
      {link || externalLink ? (
        <Link
          tw="text-quickDark"
          style={{ padding: "1rem" }}
          to={link ? `/${link?.slug.current}` : ""}
        >
          <Card></Card>
        </Link>
      ) : (
        <Card></Card>
      )}
    </Container>
  );
};

export default FeatureCard;
