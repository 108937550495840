import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import PortableText from "@sanity/block-content-to-react";
import ButtonNormal from "../../ui/ButtonNormal";
import SanityImage from "gatsby-plugin-sanity-image";


const Container = styled.div`
    ${tw`flex flex-col items-center md:mx-10 mx-4 md:py-28 py-16 bg-white relative`};
    background-color: rgba(0,0,0,0);
    > div {
        ${tw`mx-auto`}
    }
`;

const StyledPortableText = styled(PortableText)`
    ${tw`flex flex-col text-left lg:w-1/2 text-center mx-auto pt-16 pb-32`}
    >p {
        ${tw`lg:w-3/4 mx-auto`}
        margin-bottom: 4rem;
    }
    color: #f0e8e0;
`;

const StyledButtonNormal = styled(ButtonNormal)`
    ${tw`mr-0`}
`;

const ButtonWrapper = styled.div`
    ${tw`flex flex-1 mr-4 lg:(justify-start) justify-between mx-auto`}
    div {
        ${tw`mr-4`}
    }
    :last-child > {
        :last-child {
            ${tw`mr-0`}
        }
    }
`;

const StyledSanityImage = styled(SanityImage)`
    z-index: -1;
    ${tw`absolute `}
`;

const serializers = {
    types: {
        buttons: ({ node }) => {
            return (
                <>
                    <ButtonWrapper>
                        {node.buttons.map((button, index) => {
                            return (
                                <StyledButtonNormal
                                    button={button}
                                    key={index}
                                />
                            );
                        })}
                    </ButtonWrapper>
                </>
            );
        },
    },
};

const HeroCentered = ({ block }) => {
    return (
        <Container tw="py-0">
            
                <StyledPortableText
                    blocks={block._rawText}
                    serializers={serializers}
                />
                <StyledSanityImage
                    {...block.image.image}
                    alt={block.image.title}
                ></StyledSanityImage>
        </Container>
    );
};

export default HeroCentered;

export const query = graphql`
    fragment HeroCenteredFragment on SanityHeroCentered {
        _key
        _type
        _rawText(resolveReferences: { maxDepth: 10 })
        image {
            _key
            _type
            image {
                ...ImageWithPreview
            }
            title
        }
    }
`;
