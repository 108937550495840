import React from "react";
import { graphql, navigate } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import SanityImage from "gatsby-plugin-sanity-image";
import ButtonNormal from "../../ui/ButtonNormal";

// maintain: Here you have to add a new country
const readMore = {
  da: "Læs mere",
  us: "Read more",
  no: "Les mer",
};

const PhoneContainer = styled.div`
  ${tw`flex bg-white md:mx-10 mx-4 pb-16`};
  flex: 1;
  display: flex;
  justify-content: space-evenly;

  @media only screen and (max-width: 768px) {
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-coordinate: 0 0;
    scroll-snap-points-x: repeat(100%);
    scroll-snap-type: x mandatory;
    justify-content: flex-start;
    -webkit-overflow-scrolling: touch;
    section {
      flex: 0 0 calc(100vw - 30px);
      scroll-snap-align: start;
    }
  }
`;

const Container = styled.div`
  flex-wrap: wrap;
  ${tw`flex mx-12 py-32 px-32 bg-white`}
  justify-content: space-between;
  ${tw`flex items-start md:mx-10 mx-4 md:p-28 py-16 px-6 bg-white`};
  > div {
    flex-wrap: wrap;
    ${tw`flex`}
    justify-content: space-between;
    ${tw`flex items-start bg-white`};
    max-width: 1280px;
    grid-gap: 2rem;
  }
`;

const Card = styled.div`
  width: 350px;
  overflow: hidden;
  ${tw`mb-16`}

  @media only screen and (max-width: 768px) {
    overflow: hidden;
    ${tw`mb-16`}
    width: auto;

  }

  a {
    ${tw`text-quickDark`}
  }
  a:visited {
    ${tw`text-quickDark`}
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const CardTitle = styled.p`
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: -0.01rem;
  font-weight: 700;
  ${tw`mt-0 mb-3`}
`;

const StyledSanityImage = styled(SanityImage)`
  width: 100%;
`;

const StyledButton = styled.button`
  ${tw`bg-quickPrimary text-quickSandLighter mx-auto`}
  border: none;
  font-weight: 700;
  transition: all 0.2s;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.06);
  :hover {
    box-shadow: 0 16px 20px rgba(0, 0, 0, 0.1);
  }
  :active {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
  }
  cursor: pointer;
  padding: 0.5rem 2rem;
`;

const CardDescription = styled.p`
  min-height: 4.5rem
`

const ClientCases = ({ block }) => {
  return (
    <Container>
      <h1>{block.title}</h1>
      <h5 tw="md:w-1/2 w-full mb-16">{block.subtitle}</h5>
      <div>
        {block.cases.map((cases, index) => {
          const { title, image, description, subtitle, link } = cases;
          const imageUrl = image?.image;

          return (
            <Card key={index}>              
                <Wrapper>
                  <StyledSanityImage
                    {...imageUrl}
                    alt={image.title}
                  ></StyledSanityImage>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "1.5rem",
                    }}
                  ></div>
                  <span tw="opacity-50">{subtitle}</span>
                  <CardTitle>{title}</CardTitle>
                  <CardDescription>{description}</CardDescription>
                  <StyledButton
                    onClick={() => navigate("/" + link.slug.current)}
                  >
                    {readMore[block.country]}
                  </StyledButton>
                </Wrapper>
            </Card>
          );
        })}
      </div>
    </Container>
  );
};

export default ClientCases;

export const query = graphql`
  fragment ClientCasesFragment on SanityClientCases {
    _key
    _type
    cases {
      image {
        image {
          ...ImageWithPreview
        }
        title
      }
      title
      subtitle
      description
      link {
        slug {
          current
        }
      }
    }
    title
    subtitle
    country
  }
`;
